import React from "react";
import styles from "./styles.scss";
import logo from "./logo.svg";
import textPath from "./CoGText.svg";

const Cog = ()=>(<>
    <img src={logo} className={styles.logoC} />
    <img src={textPath} className={styles.logoText} />
</>);

export default ()=>(
    <div className={styles.root}>
        <Cog />
        <div className={styles.text}>
            Access Logs
        </div>
    </div>
);