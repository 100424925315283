// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./myriadpro-light.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:'MyriadPro-Light';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");font-weight:300}.b_REQ3-pQ_x96jeQTLxzx{font-family:MyriadPro-Light;font-size:2.5vw;color:#444444}.AEHRpBLee_ltWaQP0muu2{font-size:2.2vw;align-self:flex-start}._2cKfOEM4TsHxPJl627QZtc{display:flex;flex-direction:column;align-items:center;width:50vw}.K0ji14pX03jAe3dr4HV9k{display:flex;width:100vw;height:100vh;align-items:flex-start;justify-content:center}._36TNuP8_ywFJ_ggyYt8xQn{display:flex;flex-direction:column;align-items:center;width:80vw}body{font:1.8vw MyriadPro-Light;color:#555555}.gzf0UHyYnpZiOQA-YcXgF{color:#99396F}\n", ""]);
// Exports
exports.locals = {
	"text": "b_REQ3-pQ_x96jeQTLxzx",
	"previousDayText": "AEHRpBLee_ltWaQP0muu2",
	"memberContainer": "_2cKfOEM4TsHxPJl627QZtc",
	"root": "K0ji14pX03jAe3dr4HV9k",
	"wrapper": "_36TNuP8_ywFJ_ggyYt8xQn",
	"sssave": "gzf0UHyYnpZiOQA-YcXgF"
};
module.exports = exports;
