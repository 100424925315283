import React, {useState} from "react";
import moment from 'moment-timezone';

import styles from "./styles.scss";

export interface member{
  key:number;
  uid:string;
  sign_in_time:Date;
  sign_out_time:Date|null;
  forced_sign_out:number;
};

export default ({ info, active=false }: { info: member; active?:boolean}) => (
  <div className={`${styles.container} ${styles.text} ${active && styles.active || ""}`}>
    <div>{info.uid}</div>
    <div className={styles.flexFill}/>
    <div>{moment.utc(info.sign_in_time).tz('America/New_York').format('ddd, h:mmA')}</div>
    {info.sign_out_time && <>
      <div className={styles.dash}>-</div>
      <div>{moment.utc(info.sign_out_time).tz('America/New_York').format('h:mmA')}</div>
    </>}
  </div>
);