import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';

import axios from 'axios';

import './styles.scss';
import styles from "./styles.scss";
import Header from "./header";
import Member from "./member";

const Index = () => {
  const [entries, setEntries]=useState([])

  const getEntries = async ()=>{
    try {
      const memberEntries = await
      axios.get("/api/signinout/entries")
      setEntries(memberEntries.data.sort((a, b) => b['key'] - a['key'])); // set state
      } catch (err) {
        console.error(err.message);
      }
  };

  useEffect(() => {
    getEntries();
    const interval=setInterval(()=>{
      getEntries();
    },10000)
    return()=>clearInterval(interval)
  }, []);

  return (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.memberContainer}>
        <div className={styles.text}>Active Members</div>
        {entries.filter(entry => entry.sign_out_time == null).map(filteredEntry=>(
          <Member info={filteredEntry} active/>
        ))}
      </div>
      <div className={styles.memberContainer}>
        <div className={styles.text}>Previous Access</div>
        {entries.filter(entry => entry.sign_out_time != null).map(filteredEntry=>(
          <Member info={filteredEntry}/>
        ))}
      </div>
    </div>
  </div>
)};

ReactDOM.render(<Index />, document.getElementById('root'));
