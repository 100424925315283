// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._174us5_vQkGqe1t9qEvYHv{width:38.07292vw;height:13.64583vw;position:relative}._1dppKK0ua745n-uoB1O6Pv{font-size:3vw;color:#99396F;text-align:left;position:absolute;top:70.99237%;left:47.87962%}._2K5iBsEFBRfPNoTv1J-2di{color:black;position:absolute;top:0%;left:0%;height:100%}._34Y3SLNQ3r04DUS52OXVwv{position:absolute;left:34.47332%;top:35.1145%;height:35.87786%}\n", ""]);
// Exports
exports.locals = {
	"root": "_174us5_vQkGqe1t9qEvYHv",
	"text": "_1dppKK0ua745n-uoB1O6Pv",
	"logoC": "_2K5iBsEFBRfPNoTv1J-2di",
	"logoText": "_34Y3SLNQ3r04DUS52OXVwv"
};
module.exports = exports;
