// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3q5kxWShLJvGcDSGEe-x74{display:flex;justify-content:space-between;width:100%;background-color:#AAAAAA;padding:.9vw 1.35vw;border-radius:.45vw;margin:.225vw 0}._3MQAlMPm_EO4wYMJ1pnPid{background-color:#D9F1A7}.t40AwHEQLNhAuWQC5w-ic{flex-grow:1}.BaCV4LuwUpYfNEs-hzX1l{height:1.8vw}._3d-NDrCfZKsg68CVN7v5gN{width:6vw;margin-right:1vw;background-color:#ffffff}._3jqZJaBEBcqf4Oxr7WG_lu{color:#BCBCBC;margin-right:1vw}._3jqZJaBEBcqf4Oxr7WG_lu._3MQAlMPm_EO4wYMJ1pnPid{color:#BF4747}._3LWihbWC5HEa4eezFVZ5fs{padding:0 .45vw}\n", ""]);
// Exports
exports.locals = {
	"container": "_3q5kxWShLJvGcDSGEe-x74",
	"active": "_3MQAlMPm_EO4wYMJ1pnPid",
	"flexFill": "t40AwHEQLNhAuWQC5w-ic",
	"text": "BaCV4LuwUpYfNEs-hzX1l",
	"thermInput": "_3d-NDrCfZKsg68CVN7v5gN",
	"therm": "_3jqZJaBEBcqf4Oxr7WG_lu",
	"dash": "_3LWihbWC5HEa4eezFVZ5fs"
};
module.exports = exports;
